/**
 * @generated SignedSource<<6d43b535d0d574f659f1c4dbe7c07caf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MatchingMemoryListQuery$variables = {
  pattern: string;
};
export type MatchingMemoryListQuery$data = {
  readonly rem: {
    readonly " $fragmentSpreads": FragmentRefs<"MatchingMemoryPage_rem">;
  };
};
export type MatchingMemoryListQuery = {
  response: MatchingMemoryListQuery$data;
  variables: MatchingMemoryListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pattern"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "pattern"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MatchingMemoryListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Rem",
        "kind": "LinkedField",
        "name": "rem",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Literal",
                "name": "count",
                "value": 10
              },
              {
                "kind": "Variable",
                "name": "pattern",
                "variableName": "pattern"
              }
            ],
            "kind": "FragmentSpread",
            "name": "MatchingMemoryPage_rem"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MatchingMemoryListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Rem",
        "kind": "LinkedField",
        "name": "rem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "MemoryConnection",
            "kind": "LinkedField",
            "name": "search",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MemoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Memory",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "query"
            ],
            "handle": "connection",
            "key": "MatchingMemoryPage_search",
            "kind": "LinkedHandle",
            "name": "search"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "229d806710a4fa0ae8dc2de95fcb9c1f",
    "id": null,
    "metadata": {},
    "name": "MatchingMemoryListQuery",
    "operationKind": "query",
    "text": "query MatchingMemoryListQuery(\n  $pattern: String!\n) {\n  rem {\n    ...MatchingMemoryPage_rem_2QeWhh\n  }\n}\n\nfragment MatchingMemoryPage_rem_2QeWhh on Rem {\n  search(query: $pattern, first: 10) {\n    pageInfo {\n      count\n      hasPreviousPage\n      hasNextPage\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        key\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "405496b6cc0f599796dd82aa790721ea";

export default node;
