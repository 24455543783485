/**
 * @generated SignedSource<<fea2f6afd8e1d15fc9b95ad41ca4c550>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AppQuery$variables = {};
export type AppQuery$data = {
  readonly title: {
    readonly mine: {
      readonly found: boolean;
      readonly text: string;
    };
  } | null;
  readonly users: {
    readonly me: {
      readonly avatar: {
        readonly image48: string | null;
      };
      readonly coordinatorToken: string | null;
      readonly id: string;
      readonly name: string;
      readonly roles: ReadonlyArray<{
        readonly name: string;
      }>;
    };
  };
};
export type AppQuery = {
  response: AppQuery$data;
  variables: AppQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserSet",
  "kind": "LinkedField",
  "name": "users",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Avatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image48",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coordinatorToken",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "set",
    "value": "title"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "found",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": "title",
        "args": (v3/*: any*/),
        "concreteType": "DocumentSet",
        "kind": "LinkedField",
        "name": "documents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "mine",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "documents(set:\"title\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": "title",
        "args": (v3/*: any*/),
        "concreteType": "DocumentSet",
        "kind": "LinkedField",
        "name": "documents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "mine",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "documents(set:\"title\")"
      }
    ]
  },
  "params": {
    "cacheID": "710aacb0fc75f6e348429e32d69af297",
    "id": null,
    "metadata": {},
    "name": "AppQuery",
    "operationKind": "query",
    "text": "query AppQuery {\n  users {\n    me {\n      id\n      name\n      avatar {\n        image48\n      }\n      roles {\n        name\n      }\n      coordinatorToken\n    }\n  }\n  title: documents(set: \"title\") {\n    mine {\n      text\n      found\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "836730f8aa29cc2eba77f30e5f879f96";

export default node;
