/**
 * @generated SignedSource<<6680cc2ea4bfdf33629df125b133b5df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RandomMemoryListQuery$variables = {};
export type RandomMemoryListQuery$data = {
  readonly rem: {
    readonly search: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly key: string;
        };
      }>;
    };
  };
};
export type RandomMemoryListQuery = {
  response: RandomMemoryListQuery$data;
  variables: RandomMemoryListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Rem",
    "kind": "LinkedField",
    "name": "rem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 10
          },
          {
            "kind": "Literal",
            "name": "orderField",
            "value": "RANDOM"
          }
        ],
        "concreteType": "MemoryConnection",
        "kind": "LinkedField",
        "name": "search",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MemoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Memory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "search(first:10,orderField:\"RANDOM\")"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RandomMemoryListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RandomMemoryListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0e6660bfaa4e2d18207c78279129f3bc",
    "id": null,
    "metadata": {},
    "name": "RandomMemoryListQuery",
    "operationKind": "query",
    "text": "query RandomMemoryListQuery {\n  rem {\n    search(first: 10, orderField: RANDOM) {\n      edges {\n        cursor\n        node {\n          key\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a26dba46981838ebae9dea0fa627b50c";

export default node;
