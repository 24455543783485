/**
 * @generated SignedSource<<215e30e7b2a47ac5b40707f166650744>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RandomQuoteQuery$variables = {};
export type RandomQuoteQuery$data = {
  readonly documents: {
    readonly random: {
      readonly found: boolean;
      readonly text: string;
    };
  } | null;
};
export type RandomQuoteQuery = {
  response: RandomQuoteQuery$data;
  variables: RandomQuoteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "set",
    "value": "quote"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "criteria",
    "value": {}
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "found",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RandomQuoteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "DocumentSet",
        "kind": "LinkedField",
        "name": "documents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "random",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "random(criteria:{})"
          }
        ],
        "storageKey": "documents(set:\"quote\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RandomQuoteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "DocumentSet",
        "kind": "LinkedField",
        "name": "documents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "random",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": "random(criteria:{})"
          }
        ],
        "storageKey": "documents(set:\"quote\")"
      }
    ]
  },
  "params": {
    "cacheID": "cb6c0f3a03260cd4741e7342c90731b0",
    "id": null,
    "metadata": {},
    "name": "RandomQuoteQuery",
    "operationKind": "query",
    "text": "query RandomQuoteQuery {\n  documents(set: \"quote\") {\n    random(criteria: {}) {\n      found\n      text\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b00c2b7f51b5eafc60256f9ab7e571dd";

export default node;
