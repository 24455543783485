/**
 * @generated SignedSource<<bd9a9cdc55c461f73c5f3841cdaa83f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RecentChannelQuery$variables = {};
export type RecentChannelQuery$data = {
  readonly cache: {
    readonly knownChannels: ReadonlyArray<string>;
  };
};
export type RecentChannelQuery = {
  response: RecentChannelQuery$data;
  variables: RecentChannelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Cache",
    "kind": "LinkedField",
    "name": "cache",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "knownChannels",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecentChannelQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RecentChannelQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c5196d202e9a97c43891aac6b16c54c3",
    "id": null,
    "metadata": {},
    "name": "RecentChannelQuery",
    "operationKind": "query",
    "text": "query RecentChannelQuery {\n  cache {\n    knownChannels\n  }\n}\n"
  }
};
})();

(node as any).hash = "b33c5ca20b56c7400e6a375a0e5965ed";

export default node;
