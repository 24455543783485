/**
 * @generated SignedSource<<327eacd78314ec96a20449bd790f88fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Criteria = {
  mentions?: ReadonlyArray<string> | null;
  query?: string | null;
  speakers?: ReadonlyArray<string> | null;
  subject?: string | null;
};
export type QuotePageQuery$variables = {
  c: Criteria;
};
export type QuotePageQuery$data = {
  readonly documents: {
    readonly all: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string | null;
          readonly text: string;
        };
      }>;
      readonly pageInfo: {
        readonly count: number;
        readonly hasNextPage: boolean;
      };
    };
  } | null;
};
export type QuotePageQuery = {
  response: QuotePageQuery$data;
  variables: QuotePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "c"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "set",
        "value": "quote"
      }
    ],
    "concreteType": "DocumentSet",
    "kind": "LinkedField",
    "name": "documents",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "criteria",
            "variableName": "c"
          },
          {
            "kind": "Literal",
            "name": "first",
            "value": 20
          }
        ],
        "concreteType": "DocumentConnection",
        "kind": "LinkedField",
        "name": "all",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "documents(set:\"quote\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuotePageQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuotePageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4474ea52deb1145179f9db6e2c274aad",
    "id": null,
    "metadata": {},
    "name": "QuotePageQuery",
    "operationKind": "query",
    "text": "query QuotePageQuery(\n  $c: Criteria!\n) {\n  documents(set: \"quote\") {\n    all(criteria: $c, first: 20) {\n      edges {\n        node {\n          id\n          text\n        }\n      }\n      pageInfo {\n        count\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e063f623999a0dc41b92fe0b238d3ed";

export default node;
