/**
 * @generated SignedSource<<e31b1f9f84e06d64e364d04a18ce25ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Presence = "ACTIVE" | "AWAY" | "UNKNOWN" | "%future added value";
export type PeopleQuery$variables = {};
export type PeopleQuery$data = {
  readonly titles: {
    readonly all: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly subject: string | null;
          readonly text: string;
        };
      }>;
    };
  } | null;
  readonly users: {
    readonly all: ReadonlyArray<{
      readonly avatar: {
        readonly image48: string | null;
      };
      readonly name: string;
      readonly presence: Presence;
      readonly status: {
        readonly emoji: string | null;
        readonly message: string | null;
      };
    }>;
  };
};
export type PeopleQuery = {
  response: PeopleQuery$data;
  variables: PeopleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "presence",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Avatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image48",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Status",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "set",
    "value": "title"
  }
],
v5 = [
  {
    "kind": "Literal",
    "name": "criteria",
    "value": {}
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subject",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PeopleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserSet",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "all",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "titles",
        "args": (v4/*: any*/),
        "concreteType": "DocumentSet",
        "kind": "LinkedField",
        "name": "documents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "DocumentConnection",
            "kind": "LinkedField",
            "name": "all",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "all(criteria:{})"
          }
        ],
        "storageKey": "documents(set:\"title\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PeopleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserSet",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "all",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "titles",
        "args": (v4/*: any*/),
        "concreteType": "DocumentSet",
        "kind": "LinkedField",
        "name": "documents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "DocumentConnection",
            "kind": "LinkedField",
            "name": "all",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "all(criteria:{})"
          }
        ],
        "storageKey": "documents(set:\"title\")"
      }
    ]
  },
  "params": {
    "cacheID": "2a6dfee5ec8f77c97acff2bc8fbcf4a6",
    "id": null,
    "metadata": {},
    "name": "PeopleQuery",
    "operationKind": "query",
    "text": "query PeopleQuery {\n  users {\n    all {\n      name\n      presence\n      avatar {\n        image48\n      }\n      status {\n        message\n        emoji\n      }\n      id\n    }\n  }\n  titles: documents(set: \"title\") {\n    all(criteria: {}) {\n      edges {\n        node {\n          text\n          subject\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "28094ab31a64fbe177811308b6004e81";

export default node;
