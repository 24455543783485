/**
 * @generated SignedSource<<9ccf5768e61c1bddb766127cf0a7f429>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActionBarSubmitMutation$variables = {
  channel: string;
  lines: ReadonlyArray<string>;
  set: string;
};
export type ActionBarSubmitMutation$data = {
  readonly createDocument: {
    readonly id: string | null;
  };
};
export type ActionBarSubmitMutation = {
  response: ActionBarSubmitMutation$data;
  variables: ActionBarSubmitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "channel"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lines"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "set"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "channel",
        "variableName": "channel"
      },
      {
        "kind": "Variable",
        "name": "lines",
        "variableName": "lines"
      },
      {
        "kind": "Variable",
        "name": "set",
        "variableName": "set"
      }
    ],
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "createDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActionBarSubmitMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ActionBarSubmitMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "cddf9284ec903c2325f34e382ad6750e",
    "id": null,
    "metadata": {},
    "name": "ActionBarSubmitMutation",
    "operationKind": "mutation",
    "text": "mutation ActionBarSubmitMutation(\n  $set: String!\n  $channel: String!\n  $lines: [ID!]!\n) {\n  createDocument(set: $set, channel: $channel, lines: $lines) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6207ead903199684c65e05bfe37267af";

export default node;
