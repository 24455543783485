/**
 * @generated SignedSource<<1bf6fbca9d5e17bddc2cae9a96e22e7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Criteria = {
  mentions?: ReadonlyArray<string> | null;
  query?: string | null;
  speakers?: ReadonlyArray<string> | null;
  subject?: string | null;
};
export type ProfileQuery$variables = {
  name: string;
  titleCriteria: Criteria;
};
export type ProfileQuery$data = {
  readonly quotes: {
    readonly rank: number;
  } | null;
  readonly titles: {
    readonly all: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly text: string;
        };
      }>;
    };
  } | null;
  readonly users: {
    readonly current: {
      readonly avatar: {
        readonly image192: string | null;
      };
      readonly topReactionsGiven: ReadonlyArray<{
        readonly count: number;
        readonly emoji: {
          readonly name: string;
          readonly url: string | null;
        };
      }>;
      readonly topReactionsReceived: ReadonlyArray<{
        readonly count: number;
        readonly emoji: {
          readonly name: string;
          readonly url: string | null;
        };
      }>;
    } | null;
  };
};
export type ProfileQuery = {
  response: ProfileQuery$data;
  variables: ProfileQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "titleCriteria"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Avatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image192",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 10
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Emoji",
    "kind": "LinkedField",
    "name": "emoji",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": (v3/*: any*/),
  "concreteType": "EmojiCount",
  "kind": "LinkedField",
  "name": "topReactionsReceived",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": "topReactionsReceived(limit:10)"
},
v6 = {
  "alias": null,
  "args": (v3/*: any*/),
  "concreteType": "EmojiCount",
  "kind": "LinkedField",
  "name": "topReactionsGiven",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": "topReactionsGiven(limit:10)"
},
v7 = [
  {
    "kind": "Literal",
    "name": "set",
    "value": "title"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "criteria",
    "variableName": "titleCriteria"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v10 = {
  "alias": "quotes",
  "args": [
    {
      "kind": "Literal",
      "name": "set",
      "value": "quote"
    }
  ],
  "concreteType": "DocumentSet",
  "kind": "LinkedField",
  "name": "documents",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "speaker",
          "variableName": "name"
        }
      ],
      "kind": "ScalarField",
      "name": "rank",
      "storageKey": null
    }
  ],
  "storageKey": "documents(set:\"quote\")"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserSet",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": "current",
            "args": (v1/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "withName",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "titles",
        "args": (v7/*: any*/),
        "concreteType": "DocumentSet",
        "kind": "LinkedField",
        "name": "documents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "DocumentConnection",
            "kind": "LinkedField",
            "name": "all",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "documents(set:\"title\")"
      },
      (v10/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserSet",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": "current",
            "args": (v1/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "withName",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "titles",
        "args": (v7/*: any*/),
        "concreteType": "DocumentSet",
        "kind": "LinkedField",
        "name": "documents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "DocumentConnection",
            "kind": "LinkedField",
            "name": "all",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "documents(set:\"title\")"
      },
      (v10/*: any*/)
    ]
  },
  "params": {
    "cacheID": "0b5187d4d2ab7e23ca2f29951b25d034",
    "id": null,
    "metadata": {},
    "name": "ProfileQuery",
    "operationKind": "query",
    "text": "query ProfileQuery(\n  $name: String!\n  $titleCriteria: Criteria!\n) {\n  users {\n    current: withName(name: $name) {\n      avatar {\n        image192\n      }\n      topReactionsReceived(limit: 10) {\n        count\n        emoji {\n          name\n          url\n        }\n      }\n      topReactionsGiven(limit: 10) {\n        count\n        emoji {\n          name\n          url\n        }\n      }\n      id\n    }\n  }\n  titles: documents(set: \"title\") {\n    all(first: 100, criteria: $titleCriteria) {\n      edges {\n        node {\n          text\n          id\n        }\n      }\n    }\n  }\n  quotes: documents(set: \"quote\") {\n    rank(speaker: $name)\n  }\n}\n"
  }
};
})();

(node as any).hash = "6909612b595759bd5819ae0b5de00978";

export default node;
