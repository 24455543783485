/**
 * @generated SignedSource<<a5c5925e47a673202d8e6bbba19f6f8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventsQuery$variables = {};
export type EventsQuery$data = {
  readonly calendarURL: string;
};
export type EventsQuery = {
  response: EventsQuery$data;
  variables: EventsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "calendarURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EventsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "db7399016ef01122a3905fafde2bf555",
    "id": null,
    "metadata": {},
    "name": "EventsQuery",
    "operationKind": "query",
    "text": "query EventsQuery {\n  calendarURL\n}\n"
  }
};
})();

(node as any).hash = "388cd7e5bbbedc329dded72e8648dbf6";

export default node;
